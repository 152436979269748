import { faCircleDot } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';

interface PlaybarProps {
    startTime: number;
    endTime: number;
    time: number;
    maxTime: number;
    onStartTimeChange: (newTime: number) => void;
    onEndTimeChange: (newTime: number) => void;
    onTimeChange: (newTime: number) => void;
}

const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

const Playbar: React.FC<PlaybarProps> = ({
    startTime,
    endTime,
    time,
    maxTime,
    onStartTimeChange,
    onEndTimeChange,
    onTimeChange
}) => {
    const [dragging, setDragging] = useState<null | 'start' | 'play' | 'end'>(null);

    const handleMouseMove = useCallback((event: MouseEvent) => {
        if (dragging) {
            const container = document.getElementById('playbar-container')!.getBoundingClientRect();
            const newTime = ((event.clientX - container.left) / (container.width)) * maxTime;

            if (dragging === 'start') {
                onStartTimeChange(clamp(newTime, 0, Math.min(endTime - 1, time)));
            } else if (dragging === 'play') {
                onTimeChange(clamp(newTime, startTime, endTime));
            } else if (dragging === 'end') {
                onEndTimeChange(clamp(newTime, Math.max(startTime + 1, time), maxTime));
            }
        }
    }, [dragging, maxTime, startTime, endTime, time, onStartTimeChange, onEndTimeChange, onTimeChange]);

    const handleMouseUp = useCallback(() => {
        setDragging(null);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    }, [handleMouseMove]);

    const handleMouseDown = useCallback((thumb: 'start' | 'play' | 'end') => {
        setDragging(thumb);
    }, []);

    useEffect(() => {
        if (dragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging, handleMouseMove, handleMouseUp]);

    const positionStyle = (timeValue: number, align: 'r' | 'l' | 'c'= 'l') => ({
        left: `calc(${(timeValue / maxTime) * 100}% - ${align === 'r' ? '20px' : align === 'c' ? '10px' : '0px'})`,
        position: 'absolute' as const,
        width: '20px',
        height: '100%',
        backgroundColor: 'transparent',
        cursor: 'pointer'
    });

    return (
        <div id="playbar-container" style={{ position: 'relative', width: '100%', height: '20px', backgroundColor: '#ddd', margin: '0 20px' }}>
            <div style={{ position: 'absolute', left: 0, right: `${100 - (startTime / maxTime) * 100}%`, height: '100%', backgroundColor: '#9bb1c9' }} />
            <div style={{ position: 'absolute', left: `${(startTime / maxTime) * 100}%`, right: `${(100 - (endTime / maxTime) * 100)}%`, height: '100%', backgroundColor: '#00336b' }} />
            <div style={{ position: 'absolute', left: `calc(${(endTime / maxTime) * 100}%`, right: 0, height: '100%', backgroundColor: '#9bb1c9' }} />
            <button draggable={false} style={{...positionStyle(startTime, 'r'), zIndex: 3}} onMouseDown={() => handleMouseDown('start')}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" fill='#00336b' className='h-5 w-5'><path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/></svg>
            </button>
            <button draggable={false} style={positionStyle(time, 'c')} onMouseDown={() => handleMouseDown('play')}>
                <FontAwesomeIcon icon={faCircleDot} className='h-5 w-5' color='#ffffff' />
            </button>
            <button draggable={false} style={{...positionStyle(endTime), zIndex: 3}} onMouseDown={() => handleMouseDown('end')}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" fill='#00336b' className='h-5 w-5'><path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z"/></svg>
            </button>
        </div>
    );
};

export default Playbar;

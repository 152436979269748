import { useEffect, useRef, useState } from "react";

interface InteractiveTooltipProps {
    button: React.ReactNode;
    children: React.ReactNode[];
}

const InteractiveTooltip = ({ children, button }: InteractiveTooltipProps) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const tooltipHeight = tooltipRef.current?.getBoundingClientRect().height ?? 0;




    return (
        <button aria-label="tooltip 1" className="relative"
            onMouseOver={() => setShowTooltip(true)}
            onFocus={() => setShowTooltip(true)}
            onMouseOut={() => setShowTooltip(false)}
            onBlur={() => setShowTooltip(false)}
        >
            {button}
            <div ref={tooltipRef}
                role="tooltip"
                className={`text-left z-20 w-64 absolute transition duration-150 ease-in-out right-0 ml-8 shadow-lg bg-white p-4 rounded ${showTooltip ? '' : 'opacity-0 pointer-events-none'}`}
                style={{ top: `-${tooltipHeight - 10}px` }}
            >
                {children}
            </div>
        </button >
    );
}

export default InteractiveTooltip;
export interface GridData {
    enabled: boolean;
    size: {
        x: number;
        y: number;
    };
    step: number;
    offset: {
        x: number;
        y: number;
    };
}

export const DEFAULT_GRID: GridData = {
    enabled: false,
    size: {
        x: 2,
        y: 2,
    },
    step: 0.05,
    offset: {
        x: 0,
        y: 0,
    },
};

export const getGridContent = (gridData: GridData) => {
    if (!gridData.enabled) {
        return [];
    }
    gridData.size.x = Math.min(gridData.size.x, 10);
    gridData.size.y = Math.min(gridData.size.y, 10);
    gridData.step = Math.max(gridData.step, 0.01);
    return [
        ...Array.from({ length: gridData.size.x / gridData.step +1 }, (_, i) => ({
            type: 'line' as const,
            x0: -gridData.size.x / 2 + i * gridData.step - gridData.offset.x,
            y0: -gridData.size.y / 2 - gridData.offset.y,
            x1: -gridData.size.x / 2 + i * gridData.step - gridData.offset.x,
            y1: gridData.size.y / 2 - gridData.offset.y,
            line: {
                color: '#ccc',
                width: 1,
            },
            layer: 'below' as const

        })),
        ...Array.from({ length: gridData.size.y / gridData.step + 1 }, (_, i) => ({
            type: 'line' as const,
            x0: -gridData.size.x / 2 - gridData.offset.x,
            y0: -gridData.size.y / 2 + i * gridData.step - gridData.offset.y,
            x1: gridData.size.x / 2 - gridData.offset.x,
            y1: -gridData.size.y / 2 + i * gridData.step - gridData.offset.y,
            line: {
                color: '#ccc',
                width: 1,
            },
            layer: 'below' as const

        })),
    ];
}
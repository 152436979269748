import React, { useEffect } from "react";
import JawVisualizer from "./jaw-visualizer/JawVisualizer";
import { Point } from "./jaw-visualizer/types/point";
import axios from "axios";
import Top from "./components/Top";
import Aside from "./components/Aside";
import { Card, CardHeader, Spinner, Typography } from "@material-tailwind/react";
import ModalCrop from "./jaw-visualizer/components/ModalCrop";

interface VideoPreviewProps {
    video: Blob | null;
    markers: Blob | null;
    points: Point[];
}

interface VideoData {
    normalizationStatus: "pending" | "success" | "error";
    trackingStatus: "pending" | "success" | "error";
}

const SuccessVideo: VideoData = {
    normalizationStatus: "success",
    trackingStatus: "success",
};

function App() {
    const [openModalCrop, setOpenModalCrop] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    const [props, setProps] = React.useState<VideoPreviewProps>();
    const blob = new Blob();

    const status = SuccessVideo;

    const client = axios.create();

    const trackingId = window.location.pathname.split("/")[1];

    useEffect(() => {
        const fetchProps = async () => {
            if (!status || status.normalizationStatus !== "success") {
              return
            }
            if (!status || status.trackingStatus !== "success") {
                const video = await client.get(`/files/normalized.mp4`, { responseType: "blob" }).then((response) => response.data);
                setProps({ video, markers: null, points: [] });
                return;
            }

            const points = (await client.get(`/files/markers.csv`)).data.split("\n").map((line: string) => {
                const [x, y] = line.split(",").map(parseFloat);
                return { x, y };
            });

            const video = await client.get(`/files/normalized.mp4`, { responseType: "blob" }).then((response) => response.data);
            const markers = await client.get(`/files/markers.mp4`, { responseType: "blob" }).then((response) => response.data);

            setProps({ video, markers, points });
        };
        fetchProps();
    }, [status]);

    const handleOpen = () => {
        setOpen(!open);
    };

    const cssReturnNormalization = () => {
        if (status?.normalizationStatus === "pending") {
            return "bg-blue-500 animate-pulse";
        } else if (status?.normalizationStatus === "success") {
            return "bg-green-500";
        } else {
            return "bg-red-500";
        }
    };

    const cssReturnTracking = () => {
        if (status?.trackingStatus === "pending" && status?.normalizationStatus === "pending") {
            return "bg-gray-500";
        } else if (status?.trackingStatus === "pending" && status?.normalizationStatus !== "pending") {
            return "bg-blue-500 animate-pulse";
        } else if (status?.trackingStatus === "success") {
            return "bg-green-500";
        } else {
            return "bg-red-500";
        }
    };

    console.log(props)

    return (
        <div className="h-screen bg-blue-gray-50/50 overflow-x-hidden">
            {props && <ModalCrop open={openModalCrop} setOpen={setOpenModalCrop} video={props.video ?? blob} trackingId={trackingId} />}
            <div className="p-4 xl:ml-80">
                <Top handleOpen={handleOpen} />
                <Aside open={open} handleOpen={handleOpen} />
                <div className="mt-12 mb-8 flex flex-col gap-12  h-full">
                    <Card
                        className="h-[80vh]"
                        placeholder=""
                        onPointerEnterCapture={() => console.log("enter")}
                        onPointerLeaveCapture={() => console.log("enter")}
                    >
                        <CardHeader
                            placeholder=""
                            onPointerEnterCapture={() => console.log("enter")}
                            onPointerLeaveCapture={() => console.log("enter")}
                            variant="gradient"
                            color="deep-purple"
                            className="mb-4 p-4 flex"
                        >
                            <Typography
                                placeholder=""
                                onPointerEnterCapture={() => console.log("enter")}
                                onPointerLeaveCapture={() => console.log("enter")}
                                variant="h6"
                                color="white"
                                className={`first-step whereAmIForIt-step pl-0`}
                            >
                                Analyse
                            </Typography>
                        </CardHeader>
                        <div className="w-full overflow-auto px-4">
                            {(!props?.markers || !props?.video || props?.points.length === 0) ? (
                                <div className="flex flex-col gap-12 justify-center items-center pt-12">
                                    {status?.normalizationStatus !== "error" && status?.trackingStatus !== "error" && (
                                        <>
                                            <h1 className="text-2xl">Chargement...</h1>
                                            <Spinner
                                                onPointerEnterCapture={() => console.log()}
                                                onPointerLeaveCapture={() => console.log()}
                                                color="deep-purple"
                                                className="h-10 w-10"
                                            />
                                        </>
                                    )}
                                    {status?.normalizationStatus === "error" && (
                                        <div className="flex flex-col justify-center items-center w-fit bg-[#e30613] text-white px-4 py-2 rounded-lg">
                                            <span className="text-center">
                                                Une erreur est survenue lors de la normalisation
                                                <br />
                                                Veuillez réessayer
                                            </span>
                                        </div>
                                    )}
                                    {status?.trackingStatus === "error" && (
                                        <div className="flex flex-col justify-center items-center w-fit bg-[#e30613] text-white px-4 py-2 rounded-lg">
                                            <span className="text-center">
                                                Une erreur est survenue lors du tracking. Appuyez sur le bouton ci-dessous pour recadrer votre vidéo afin de
                                                relancer le processus
                                            </span>
                                            <button className="bg-white text-[#e30613] px-4 py-2 mt-2 rounded-lg" onClick={() => setOpenModalCrop(true)}>
                                                Recadrer
                                            </button>
                                        </div>
                                    )}
                                    <div className="w-full flex flex-col md:flex-row">
                                        <div className="flex flex-col justify-center items-center w-full">
                                            <div className={`w-2/3 h-4 mb-1 rounded-full ${cssReturnNormalization()} transition-all`}></div>
                                            <span>Normalisation</span>
                                        </div>
                                        <div className="flex flex-col justify-center items-center w-full">
                                            <div className={`w-2/3 h-4 mb-1 rounded-full ${cssReturnTracking()} transition-all`}></div>
                                            <span>Suivi</span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <JawVisualizer
                                    points={props?.points ?? []}
                                    video={props?.video ?? new Blob()}
                                    markerVideo={props?.markers ?? new Blob()}
                                    trackingId={trackingId}
                                    width="100%"
                                    height="100%"
                                />
                            )}
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default App;

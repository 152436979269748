import React from 'react';
import Plot from 'react-plotly.js';
import { Config, Data, Layout } from 'plotly.js';
import { Point } from './types/point';
import { GridData, getGridContent } from './grid';

interface VisualizationGridProps {
    points: Point[];
    disabled?: boolean;
    grid: GridData
}

const VisualizationGrid: React.FC<VisualizationGridProps> = ({ points, disabled, grid }) => {
    const xValues = points.map(point => point.x);
    const yValues = points.map(point => point.y);

    const data: Data[] = [{
        x: xValues,
        y: yValues,
        type: 'scatter',
        mode: 'lines+markers',
        marker: { color: 'red' },
    }];
    const layout: Partial<Layout> = {
        xaxis: {
            title: 'X Axis',
            showgrid: false,
            zeroline: true,
            scaleanchor: 'y',
            fixedrange: disabled,
            scaleratio: 1,
            domain: [-1, 1],
        },
        yaxis: {
            title: 'Y Axis',
            showgrid: false,
            zeroline: true,
            fixedrange: disabled,
            scaleratio: 1,
            domain: [-1, 1],
        },
        dragmode: 'pan',
        autosize: true,
        hovermode: 'closest',
        uirevision: 'true',

        margin: {
            l: 60,
            r: 10,
            b: 50,
            t: 10,
            pad: 4,
        },
        shapes: getGridContent(grid),
    };

    const config: Partial<Config> = {
        scrollZoom: true,
        displayModeBar: false,

    };

    return <Plot
        data={data}
        layout={layout}
        config={config}
        style={{
            width: '100%',
            height: 'calc(55% - 20px)',
        }}
        useResizeHandler
    />;
};

export default VisualizationGrid;

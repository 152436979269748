import { ArrowRightOnRectangleIcon, Bars3Icon, ChatBubbleLeftIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { IconButton, Navbar } from "@material-tailwind/react";
import React from "react";

interface TopProps {
    handleOpen: () => void;
}

export default function Top({ handleOpen }: Readonly<TopProps>): React.JSX.Element {

    return (
        <Navbar
            onPointerEnterCapture={() => console.log("enter")}
            onPointerLeaveCapture={() => console.log("leave")}
            placeholder=""
            color="white"
            className={`rounded-xl transition-all top-4 z-30 py-3 shadow-md shadow-indigo-gray-500/5`}
            fullWidth
            blurred
        >
            <div className="flex justify-between flex-row md:items-center">
                <div className="capitalize">
                    <h6 className="text-royal-900 font-bold ">Administrateur</h6>
                </div>
                <div className="flex items-center">
                    <IconButton
                        onPointerEnterCapture={() => console.log("enter")}
                        onPointerLeaveCapture={() => console.log("leave")}
                        placeholder=""
                        variant="text"
                        color="red"
                        className="grid xl:hidden"
                        onClick={() => handleOpen()}
                    >
                        <Bars3Icon strokeWidth={3} className="h-6 w-6 text-capucine-700" />
                    </IconButton>
                    <IconButton
                        onPointerEnterCapture={() => console.log("enter")}
                        onPointerLeaveCapture={() => console.log("leave")}
                        placeholder=""
                        variant="text"
                        color="lime"
                    >
                        <Cog6ToothIcon className="h-5 w-5 text-capucine-700" />
                    </IconButton>
                    <IconButton
                        onPointerEnterCapture={() => console.log("enter")}
                        onPointerLeaveCapture={() => console.log("leave")}
                        placeholder=""
                        variant="text"
                        color="lime"
                    >
                        <ChatBubbleLeftIcon className="h-5 w-5 text-capucine-700" />
                    </IconButton>
                    <IconButton
                        onPointerEnterCapture={() => console.log("enter")}
                        onPointerLeaveCapture={() => console.log("leave")}
                        placeholder=""
                        variant="text"
                        color="red"
                        onClick={() => {
                            localStorage.clear();
                            window.location.href = "/";
                        }}
                    >
                        <ArrowRightOnRectangleIcon className="h-5 w-5 text-capucine-700" />
                    </IconButton>
                </div>
            </div>
        </Navbar>
    );
}

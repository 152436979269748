import React, { useEffect, useRef, useState } from 'react';

interface VideoPreviewProps {
    video: Blob;
    frame: number;
    fps: number;
    width: number | string;
    height: number | string;
}

const VideoPreview: React.FC<VideoPreviewProps> = ({ video, frame, fps, width, height }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isSeeking, setIsSeeking] = useState(false);

    // Manage currentTime based on frame and fps, only if not currently seeking
    useEffect(() => {
        if (videoRef.current && !isSeeking) {
            const videoElement = videoRef.current;
            const targetTime = frame / fps;
            if (videoElement.duration >= targetTime) {
                videoElement.currentTime = targetTime;
            }
        }
    }, [frame, fps, isSeeking]);

    // Setup the video source and handle cleanup
    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            const url = URL.createObjectURL(video);
            videoElement.src = url;

            return () => {
                URL.revokeObjectURL(url);
            };
        }
    }, [video]);

    // Event listeners for seeking state management
    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            const handleSeeking = () => setIsSeeking(true);
            const handleSeeked = () => setIsSeeking(false);

            videoElement.addEventListener('seeking', handleSeeking);
            videoElement.addEventListener('seeked', handleSeeked);

            return () => {
                videoElement.removeEventListener('seeking', handleSeeking);
                videoElement.removeEventListener('seeked', handleSeeked);
            };
        }
    }, []);

    return (
        <div className='w-full'>
            <video
                ref={videoRef}
                style={{ width, height }}
            ></video>
        </div>
    );
};

export default VideoPreview;

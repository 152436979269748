import React, { useEffect, useState } from 'react';
import VisualizationGrid from './VisualizationGrid';
import { Point } from './types/point';
import VideoPreview from './video/VideoPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePause, faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import ModalCrop from './components/ModalCrop';
import Playbar from './components/Playbar';
import { DEFAULT_GRID, GridData } from './grid';
import InteractiveTooltip from './components/InteractiveTooltip';

interface JawVisualizerProps {
    points: Point[];
    video: Blob;
    markerVideo: Blob;
    width: number | string;
    height: number | string;
    trackingId: string;
}

const GridNumberInput = ({ value, onChange, min, max, step }: { value: number, onChange: (value: number) => void, min?: number, max?: number, step?: number }) => {

    const [currentValue, setCurrentValue] = useState<string>(value.toString());

    useEffect(() => {
        if (currentValue === '')
            return;

        if (isNaN(Number(currentValue)))
            return;

        onChange(Number(currentValue));
    }, [currentValue]);

    return (
        <input
            type="number"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
            value={currentValue}
            onChange={e => setCurrentValue(e.target.value)}
            min={min}
            max={max}
            step={step}
        />
    );
}

const JawVisualizer: React.FC<JawVisualizerProps> = ({ points, video, markerVideo, width, height, trackingId }) => {
    const [openModalCrop, setOpenModalCrop] = useState(false)
    const [playing, setPlaying] = useState(false);
    const [time, setTime] = useState(0);
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(points.length - 1);
    const [grid, setGrid] = useState<GridData>(DEFAULT_GRID);

    const maxTime = points.length - 1;

    const handleTimeChange = (value: number) => {
        setTime(value);
        setPlaying(false);
    };

    const nextFrame = () => {
        if (time < maxTime) {
            setTime(time + 1);
        }
    };

    const prevFrame = () => {
        if (time > 0) {
            setTime(time - 1);
        }
    };

    if (playing) {
        if (time < endTime) {
            setTimeout(() => {
                setTime(time + 1);
            }, 50);
        } else {
            setPlaying(false);
        }
    }

    return (
        <>
            <ModalCrop open={openModalCrop} setOpen={setOpenModalCrop} video={video} trackingId={trackingId} />
            <div style={{
                width,
                height,
                position: 'relative',
            }}>
                <VisualizationGrid points={points.slice(startTime, time + 1)} disabled={playing} grid={grid} />
                <div className='flex flex-col md:flex-row justify-between gap-4 mt-4 max-h-[40%]'>
                    <VideoPreview
                        video={video}
                        frame={time}
                        fps={30}
                        width="100%"
                        height="100%"
                    />

                    <VideoPreview
                        video={markerVideo}
                        frame={time}
                        fps={30}
                        width="100%"
                        height="100%"
                    />
                </div>
                <div className='flex items-center gap-4 mt-3 bg-white absolute bottom-0 left-0 right-0 pb-2 pt-2'>
                    <button onClick={() => setPlaying(!playing)}>
                        <FontAwesomeIcon icon={playing ? faCirclePause : faPlayCircle} className='h-5 w-5' fill="#00336b" />
                    </button>
                    <button onClick={() => prevFrame()} disabled={time === 0}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className='w-5 h-5' fill={time === 0 ? "#ccc" : "#00336b"}><path d="M267.5 440.6c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29V96c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4l-192 160L64 241V96c0-17.7-14.3-32-32-32S0 78.3 0 96V416c0 17.7 14.3 32 32 32s32-14.3 32-32V271l11.5 9.6 192 160z" /></svg>
                    </button>
                    <button onClick={() => nextFrame()} disabled={time === maxTime}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className='w-5 h-5' fill={time === maxTime ? "#ccc" : "#00336b"}><path d="M52.5 440.6c-9.5 7.9-22.8 9.7-34.1 4.4S0 428.4 0 416V96C0 83.6 7.2 72.3 18.4 67s24.5-3.6 34.1 4.4l192 160L256 241V96c0-17.7 14.3-32 32-32s32 14.3 32 32V416c0 17.7-14.3 32-32 32s-32-14.3-32-32V271l-11.5 9.6-192 160z" /></svg>
                    </button>
                    <Playbar
                        onStartTimeChange={setStartTime}
                        onEndTimeChange={setEndTime}
                        onTimeChange={handleTimeChange}
                        startTime={startTime}
                        endTime={endTime}
                        time={time}
                        maxTime={maxTime}
                    />
                    <button onClick={() => setOpenModalCrop(!openModalCrop)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='#00336b' className='h-5 w-5'><path d="M128 32c0-17.7-14.3-32-32-32S64 14.3 64 32V64H32C14.3 64 0 78.3 0 96s14.3 32 32 32H64V384c0 35.3 28.7 64 64 64H352V384H128V32zM384 480c0 17.7 14.3 32 32 32s32-14.3 32-32V448h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H448l0-256c0-35.3-28.7-64-64-64L160 64v64l224 0 0 352z"/></svg>
                    </button>
                    <InteractiveTooltip button={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill='#00336b' className='h-5 w-5'><path d="M32 480a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm96-64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0-384a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0 256a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM320 416a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0-320a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm0 128a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM224 480a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm0-448a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0 256a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM416 416a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0-384a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM32 96a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM416 224a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM32 288a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm192 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm192 64a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM32 320a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM416 192a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM32 128a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm192 64a32 32 0 1 1 0-64 32 32 0 1 1 0 64z"/></svg>}>
                        <h2 className='mb-2'>Grille</h2>
                        <label className="inline-flex items-center mb-5 cursor-pointer">
                            <input type="checkbox" value="" className="sr-only peer" onChange={e => setGrid({ ...grid, enabled: e.target.checked })} />
                            <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            <span className="ms-3 text-sm text-gray-900 dark:text-gray-300">Afficher</span>
                        </label>
                        <h3>Dimensions</h3>
                        <div className='flex gap-2'>
                            <GridNumberInput value={grid.size.x} onChange={value => setGrid({ ...grid, size: { ...grid.size, x: value } })} min={0} max={10} step={0.5} />
                            <GridNumberInput value={grid.size.y} onChange={value => setGrid({ ...grid, size: { ...grid.size, y: value } })} min={0} max={10} step={0.5} />
                        </div>
                        <h3>Espacement</h3>
                        <GridNumberInput value={grid.step} onChange={value => setGrid({ ...grid, step: value })} min={0.01} max={2} step={0.01} />
                        <h3>Déplacement</h3>
                        <div className='flex gap-2'>
                            <GridNumberInput value={grid.offset.x} onChange={value => setGrid({ ...grid, offset: { ...grid.offset, x: value } })} min={-10} max={10} step={0.001} />
                            <GridNumberInput value={grid.offset.y} onChange={value => setGrid({ ...grid, offset: { ...grid.offset, y: value } })} min={-10} max={10} step={0.001} />
                        </div>
                    </InteractiveTooltip>
                </div>
            </div>
        </>
    );

};

export default JawVisualizer;

import { Button, Typography } from "@material-tailwind/react";
import "tailwindcss/tailwind.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useEffect } from "react";
import { faCirclePause, faListAlt, faPaperPlane } from "@fortawesome/free-regular-svg-icons";

interface AsideProps {
    open: boolean;
    handleOpen: () => void;
}

interface NavLinksProps {
    path: string;
    icon: ReactNode;
    name: string;
}


interface RouteDisplay {
    icon?: JSX.Element;
    name: string;
}

export interface RouteData {
    path: string;
    auth: string[];
    display?: RouteDisplay;
    element?: JSX.Element;
    routes?: RouteData[];
}

export interface RouteGroup {
    baseUrl?: string;
    title?: string;
    shownInSidebar?: boolean;
    element?: JSX.Element;
    pages: RouteData[];
}



export const routes: RouteGroup[] = [
    {
        shownInSidebar: false,
        pages: [
            {
                path: "/",
                auth: [],
            },
            {
                path: "/dash",
                auth: ["ADMIN", "LAB_CHIEF", "MODELLER", "SECRETARY", "PRODUCER", "FINALIZER", "VERIFIER", "SENDER"],
            }
        ],
    },
    {
        baseUrl: "dash",
        shownInSidebar: false,
        pages: [
            {
                path: "chat",
                auth: ["ADMIN", "LAB_CHIEF", "MODELLER"],
            },
            {
                path: "chat/:id",
                auth: ["ADMIN", "LAB_CHIEF", "MODELLER"],
            },
        ]
    },
    {
        baseUrl: "dash",
        shownInSidebar: true,
        pages: [
            {
                display: {
                    icon: <FontAwesomeIcon icon={faCirclePause} className="w-5 h-5 text-inherit" />,
                    name: "Besoin d'instruction",
                },
                path: "needInstruction",
                auth: ["ADMIN", "LAB_CHIEF"],
            },
            {
                display: {
                    icon: <FontAwesomeIcon icon={faListAlt} className="w-5 h-5 text-inherit" />,
                    name: "Suivi",
                },
                path: "allinone",
                auth: ["ADMIN", "LAB_CHIEF", "SECRETARY"],
            },
            {
                display: {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 448 512" className="w-5 h-5 text-inherit"><path d="M186.1 52.1C169.3 39.1 148.7 32 127.5 32C74.7 32 32 74.7 32 127.5v6.2c0 15.8 3.7 31.3 10.7 45.5l23.5 47.1c4.5 8.9 7.6 18.4 9.4 28.2l36.7 205.8c2 11.2 11.6 19.4 22.9 19.8s21.4-7.4 24-18.4l28.9-121.3C192.2 323.7 207 312 224 312s31.8 11.7 35.8 28.3l28.9 121.3c2.6 11.1 12.7 18.8 24 18.4s20.9-8.6 22.9-19.8l36.7-205.8c1.8-9.8 4.9-19.3 9.4-28.2l23.5-47.1c7.1-14.1 10.7-29.7 10.7-45.5v-2.1c0-55-44.6-99.6-99.6-99.6c-24.1 0-47.4 8.8-65.6 24.6l-3.2 2.8 19.5 15.2c7 5.4 8.2 15.5 2.8 22.5s-15.5 8.2-22.5 2.8l-24.4-19-37-28.8z"/></svg>,
                    name: "Commande",
                },
                path: "order",
                auth: ["ADMIN", "LAB_CHIEF"],
            },
            {
                display: {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="w-5 h-5" fill="rgb(96, 125, 139)"><path d="M128 0C92.7 0 64 28.7 64 64V288H19.2C8.6 288 0 296.6 0 307.2C0 349.6 34.4 384 76.8 384H320V288H128V64H448V96h64V64c0-35.3-28.7-64-64-64H128zM512 128H400c-26.5 0-48 21.5-48 48V464c0 26.5 21.5 48 48 48H592c26.5 0 48-21.5 48-48V256H544c-17.7 0-32-14.3-32-32V128zm32 0v96h96l-96-96z"/></svg>,
                    name: "Secrétariat",
                },
                path: "secretariat",
                auth: ["ADMIN", "LAB_CHIEF"],
            },
            {
                display: {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="h-5 w-5" fill="rgb(96, 125, 139)"><path d="M0 80C0 53.5 21.5 32 48 32h96c26.5 0 48 21.5 48 48V96H384V80c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H432c-26.5 0-48-21.5-48-48V160H192v16c0 1.7-.1 3.4-.3 5L272 288h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H272c-26.5 0-48-21.5-48-48V336c0-1.7 .1-3.4 .3-5L144 224H48c-26.5 0-48-21.5-48-48V80z"/></svg>,
                    name: "Assignation",
                },
                path: "assignation",
                auth: ["ADMIN", "LAB_CHIEF"],
            },
            {
                display: {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="h-5 w-5" fill="rgb(96, 125, 139)"><path d="M96 151.4V360.6c9.7 5.6 17.8 13.7 23.4 23.4H328.6c0-.1 .1-.2 .1-.3l-4.5-7.9-32-56 0 0c-1.4 .1-2.8 .1-4.2 .1c-35.3 0-64-28.7-64-64s28.7-64 64-64c1.4 0 2.8 0 4.2 .1l0 0 32-56 4.5-7.9-.1-.3H119.4c-5.6 9.7-13.7 17.8-23.4 23.4zM384.3 352c35.2 .2 63.7 28.7 63.7 64c0 35.3-28.7 64-64 64c-23.7 0-44.4-12.9-55.4-32H119.4c-11.1 19.1-31.7 32-55.4 32c-35.3 0-64-28.7-64-64c0-23.7 12.9-44.4 32-55.4V151.4C12.9 140.4 0 119.7 0 96C0 60.7 28.7 32 64 32c23.7 0 44.4 12.9 55.4 32H328.6c11.1-19.1 31.7-32 55.4-32c35.3 0 64 28.7 64 64c0 35.3-28.5 63.8-63.7 64l-4.5 7.9-32 56-2.3 4c4.2 8.5 6.5 18 6.5 28.1s-2.3 19.6-6.5 28.1l2.3 4 32 56 4.5 7.9z"/></svg>,
                    name: "Modélisation",
                },
                path: "modeling",
                auth: ["ADMIN", "LAB_CHIEF", "MODELLER"],
            },
            {
                display: {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="h-5 w-5" fill="rgb(96, 125, 139)" ><path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>,
                    name: "Vérification",
                },
                path: "verification",
                auth: ["ADMIN", "LAB_CHIEF", "VERIFIER"],
            },
            {
                display: {
                    icon: <FontAwesomeIcon icon={faPaperPlane} className="w-5 h-5 text-inherit" />,
                    name: "Envoi",
                },
                path: "sending",
                auth: ["ADMIN", "LAB_CHIEF", "SENDER"],
            },
            {
                display: {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="h-5 w-5" fill="rgb(96, 125, 139)"><path d="M32 32H480c17.7 0 32 14.3 32 32V96c0 17.7-14.3 32-32 32H32C14.3 128 0 113.7 0 96V64C0 46.3 14.3 32 32 32zm0 128H480V416c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V160zm128 80c0 8.8 7.2 16 16 16H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z"/></svg>,
                    name: "Archives",
                },
                path: "archives",
                auth: ["ADMIN", "LAB_CHIEF"],
            },
        ],
    },
    {
        title: "Gestion",
        baseUrl: "dash",
        shownInSidebar: true,
        pages: [
            {
                display: {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="h-5 w-5" fill="rgb(96, 125, 139)"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm0 32v64H288V256H96zM240 416h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>,
                    name: "Facturation",
                },
                path: "facturation",
                auth: ["ADMIN", "LA_MAMA", "SECRETARY"],
                routes: [
                    {
                        path: "",
                        auth: ["ADMIN", "LA_MAMA", "SECRETARY"],
                    },
                    {
                        path: "document/:id",
                        auth: ["ADMIN", "LA_MAMA", "SECRETARY"],
                    },
                    {
                        path: "form/:id?",
                        auth: ["ADMIN", "LA_MAMA", "SECRETARY"],
                    }
                ]
            },
            {
                display: {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="h-5 w-5" fill="rgb(96, 125, 139)"><path d="M175 389.4c-9.8 16-15 34.3-15 53.1c-10 3.5-20.8 5.5-32 5.5c-53 0-96-43-96-96V64C14.3 64 0 49.7 0 32S14.3 0 32 0H96h64 64c17.7 0 32 14.3 32 32s-14.3 32-32 32V309.9l-49 79.6zM96 64v96h64V64H96zM352 0H480h32c17.7 0 32 14.3 32 32s-14.3 32-32 32V214.9L629.7 406.2c6.7 10.9 10.3 23.5 10.3 36.4c0 38.3-31.1 69.4-69.4 69.4H261.4c-38.3 0-69.4-31.1-69.4-69.4c0-12.8 3.6-25.4 10.3-36.4L320 214.9V64c-17.7 0-32-14.3-32-32s14.3-32 32-32h32zm32 64V224c0 5.9-1.6 11.7-4.7 16.8L330.5 320h171l-48.8-79.2c-3.1-5-4.7-10.8-4.7-16.8V64H384z"/></svg>,
                    name: "Laboratoire",
                },
                path: "laboratory",
                auth: ["ADMIN", "LAB_CHIEF"],
            },
            {
                display: {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="h-5 w-5" fill="rgb(96, 125, 139)"><path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"/></svg>,
                    name: "Comptes",
                },
                path: "account",
                auth: ["ADMIN", "LAB_CHIEF"]
            },
            {
                display: {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-5 h-5" fill="rgb(96, 125, 139)"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm54.2 253.8c-6.1 20.3-24.8 34.2-46 34.2H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h8.2c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.7 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L144 349l-9.8 32.8z"/></svg>,
                    name: "Contrats",
                },
                path: "contracts",
                auth: ["ADMIN", "LAB_CHIEF"],
            },
            {
                display: {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="h-5 w-5" fill="rgb(96, 125, 139)"><path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/></svg>,
                    name: "Cabinets",
                },
                path: "company",
                auth: ["ADMIN", "LAB_CHIEF", "SECRETARY"],
            },
            {
                display: {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="h-5 w-5" fill="rgb(96, 125, 139)"><path d="M186.1 52.1C169.3 39.1 148.7 32 127.5 32C74.7 32 32 74.7 32 127.5v6.2c0 15.8 3.7 31.3 10.7 45.5l23.5 47.1c4.5 8.9 7.6 18.4 9.4 28.2l36.7 205.8c2 11.2 11.6 19.4 22.9 19.8s21.4-7.4 24-18.4l28.9-121.3C192.2 323.7 207 312 224 312s31.8 11.7 35.8 28.3l28.9 121.3c2.6 11.1 12.7 18.8 24 18.4s20.9-8.6 22.9-19.8l36.7-205.8c1.8-9.8 4.9-19.3 9.4-28.2l23.5-47.1c7.1-14.1 10.7-29.7 10.7-45.5v-2.1c0-55-44.6-99.6-99.6-99.6c-24.1 0-47.4 8.8-65.6 24.6l-3.2 2.8 19.5 15.2c7 5.4 8.2 15.5 2.8 22.5s-15.5 8.2-22.5 2.8l-24.4-19-37-28.8z"/></svg>,
                    name: "Praticiens",
                },
                path: "practiciens",
                auth: ["ADMIN", "LAB_CHIEF", "SECRETARY"],
            },
            {
                display: {
                    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="h-5 w-5" fill="rgb(96, 125, 139)"><path d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z"/></svg>,
                    name: "Patients",
                },
                path: "patients",
                auth: ["ADMIN", "LAB_CHIEF", "SECRETARY"],
            },
        ],
    },
];

export function getAllowedRoutes(roles: string[]): RouteGroup[] {
    return routes.map((routeGroup) => ({
        ...routeGroup,
        pages: routeGroup.pages.filter((route) =>
            route.auth.some((auth) => roles.includes(auth))
        ),
    }));
}

const NavLinks: React.FC<NavLinksProps> = ({  path, icon, name }) => {
    return (
        <div
        >
            <Button
                placeholder=""
                onPointerEnterCapture={() => console.log("enter")}
                onPointerLeaveCapture={() => console.log("leave")}
                variant={path === "/order" ? "gradient" : "text"}
                color={path === "/order" ? "deep-purple" : "blue-gray"}
                className={`flex items-center gap-6 px-4 capitalize `}
                fullWidth
            >
                <div className="flex gap-1 items-center">
                    {icon}
                </div>
                <Typography
                    placeholder=""
                    onPointerEnterCapture={() => console.log("enter")}
                    onPointerLeaveCapture={() => console.log("leave")}
                color="inherit" className="font-medium capitalize">
                    {name}
                </Typography>
            </Button>
        </div>
    );
};


export default function Aside({ open, handleOpen }: Readonly<AsideProps>): React.JSX.Element {
    const roles = ["ADMIN", "COMPANY", "PRACTICER"];
    const asideRef = React.useRef<HTMLDivElement>(null);

    const filteredRoutes = roles ? getAllowedRoutes(roles) : [];

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (asideRef.current && !asideRef.current.contains(event.target as Node)) {
                handleOpen();
            }
        }
        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open, handleOpen]);

    return (
        <aside
            ref={asideRef}
            className={`bg-white shadow-lg ${
                open ? "translate-x-0" : "-translate-x-80"
            } fixed inset-0 z-20 my-4 ml-4 h-[calc(100vh-32px)] w-72 rounded-xl transition-transform duration-300 xl:translate-x-0 overflow-y-auto scrollPretty`}
        >
            <div className="m-4">
                <a href="/dash/order">
                    <img src="https://laboratoire.lio-lft.fr/static/media/LIO.be6767bae74cac9f7bde.png" className="w-1/4 mx-auto mb-6" alt="logo_lio" />
                </a>
                {filteredRoutes.map(
                    ({ pages, title, shownInSidebar }) =>
                        shownInSidebar && (
                            <div key={title}>
                                {title && (
                                    <Typography
                                        placeholder=""
                                        onPointerEnterCapture={() => console.log("enter")}
                                        onPointerLeaveCapture={() => console.log("leave")}
                                    variant="small" color="lime" className="mx-3.5 mt-4 mb-2 font-black uppercase opacity-75">
                                        {title}
                                    </Typography>
                                )}
                                <ul className={`mb-4 flex flex-col gap-4 ${title === "Gestion" && "otherTutorials-step"}`}>
                                    {pages
                                        .filter((p) => p.display)
                                        .map(({ display, path }) => (
                                            <li key={display ? display.name : "randomName"}>
                                                <NavLinks
                                                    path={`/${path}`}
                                                    icon={display?.icon}
                                                    name={display?.name ?? path}
                                                />
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        )
                )}
            </div>
        </aside>
    );
}
